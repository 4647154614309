import React, {useState, useEffect} from 'react';
import { useMedia } from 'react-use';
import {
    Heading,
    Text,
    Box,
    SimpleGrid,
    Spinner,
    Spacer,
    VStack,
    Container,
    Button,
    useToast,
    HStack,
    Link,
} from '@chakra-ui/react';
import { FaInfoCircle } from 'react-icons/fa';

import FilmCard from '../FilmCard';
// can trim these api calls after db ratingcount and playlistcount is working in production
import { 
    searchTmdbFilmById, 
    updateAllFilmRatingCounts, 
    updateAllUserPlaylistCounts, 
    updateAllUserRatingCounts, 
    updateAllFilmGenres, 
    createAllRatingsPlaylists, 
    updateMissingImagePaths, 
    updateAllFilmOverviews, 
    updateAllFilmImdbIds, 
    createWatchlistPlaylists, 
    initializeWatchlistPlaylists,
    updateAllFollowRecords
} from '../../api';

const HomeScene = (props) => {
    const { setScene, setSelectedFilm, searchType } = props;
    const [featuredFilmsArray, setFeaturedFilmsArray] = useState([])
    const [featureIsLoading, setFeatureIsLoading] = useState(true);
    // deleter these after db ratingcount is working
    const [isUpdatingFilmRatingCounts, setIsUpdatingFilmRatingCounts] = useState(false);
    const [isUpdatingUserRatingCounts, setIsUpdatingUserRatingCounts] = useState(false);
    const [isUpdatingUserPlaylistCounts, setIsUpdatingUserPlaylistCounts] = useState(false);
    const [isUpdatingFilmGenres, setIsUpdatingFilmGenres] = useState(false);
    const [isCreatingAllRatingsPlaylists, setIsCreatingAllRatingsPlaylists] = useState(false);
    const [isUpdatingImagePaths, setIsUpdatingImagePaths] = useState(false);
    const [isUpdatingFilmOverviews, setIsUpdatingFilmOverviews] = useState(false);
    const [isUpdatingFilmImdbIds, setIsUpdatingFilmImdbIds] = useState(false);
    const [isUpdatingFollowRecords, setIsUpdatingFollowRecords] = useState(false);
    const [isCreatingWatchlistPlaylists, setIsCreatingWatchlistPlaylists] = useState(false);
    const [isInitializingWatchlistPlaylists, setIsInitializingWatchlistPlaylists] = useState(false);
    const toast = useToast();

    const handleUpdateFilmRatingCounts = async () => {
        setIsUpdatingFilmRatingCounts(true);
        try {
            const result = await updateAllFilmRatingCounts();
            toast({
                title: 'Success',
                description: result.message,
                status: 'success',
                duration: 5000,
                isClosable: true,
            });
        } catch (error) {
            toast({
                title: 'Error',
                description: 'Failed to update rating counts',
                status: 'error',
                duration: 5000,
                isClosable: true,
            });
        } finally {
            setIsUpdatingFilmRatingCounts(false);
        }
    };

    const handleUpdateUserRatingCounts = async () => {
        setIsUpdatingUserRatingCounts(true);
        try {
            await updateAllUserRatingCounts();
            toast({
                title: "Success",
                description: "All user rating counts updated successfully",
                status: "success",
                duration: 5000,
                isClosable: true,
            });
        } catch (error) {
            console.error('Error updating user rating counts:', error);
            toast({
                title: "Error",
                description: "Failed to update user rating counts",
                status: "error",
                duration: 5000,
                isClosable: true,
            });
        } finally {
            setIsUpdatingUserRatingCounts(false);
        }
    };

    const handleUpdateUserPlaylistCounts = async () => {
        setIsUpdatingUserPlaylistCounts(true);
        try {
            const result = await updateAllUserPlaylistCounts();
            console.log('Update result:', result); // Add this line for debugging
            toast({
                title: 'Success',
                description: `${result.updatedUsers} user playlist counts updated successfully`,
                status: 'success',
                duration: 5000,
                isClosable: true,
            });
        } catch (error) {
            console.error('Error updating playlist counts:', error); // Add this line for debugging
            toast({
                title: 'Error',
                description: error.message || 'Failed to update playlist counts',
                status: 'error',
                duration: 5000,
                isClosable: true,
            });
        } finally {
            setIsUpdatingUserPlaylistCounts(false);
        }
    };

    const handleUpdateAllFilmGenres = async () => {
        setIsUpdatingFilmGenres(true);
        try {
            await updateAllFilmGenres();
            toast({
                title: "Success",
                description: "All film genres have been updated.",
                status: "success",
                duration: 5000,
                isClosable: true,
            });
        } catch (error) {
            toast({
                title: "Error",
                description: "Failed to update film genres.",
                status: "error",
                duration: 5000,
                isClosable: true,
            });
        } finally {
            setIsUpdatingFilmGenres(false);
        }
    };

    const handleCreateAllRatingsPlaylists = async () => {
        setIsCreatingAllRatingsPlaylists(true);
        try {
            const result = await createAllRatingsPlaylists();
            toast({
                title: 'Success',
                description: `Created All Ratings playlists for ${result.updatedUsers} users`,
                status: 'success',
                duration: 5000,
                isClosable: true,
            });
        } catch (error) {
            toast({
                title: 'Error',
                description: 'Failed to create All Ratings playlists',
                status: 'error',
                duration: 5000,
                isClosable: true,
            });
        } finally {
            setIsCreatingAllRatingsPlaylists(false);
        }
    };

    const handleCreateWatchlistPlaylists = async () => {
        setIsCreatingWatchlistPlaylists(true);
        try {
            const result = await createWatchlistPlaylists();
            toast({
                title: 'Success',
                description: `Created Watchlist playlists for ${result.updatedUsers} users`,
                status: 'success',
                duration: 5000,
                isClosable: true,
            });
        } catch (error) {
            toast({
                title: 'Error',
                description: 'Failed to create Watchlist playlists',
                status: 'error',
                duration: 5000,
                isClosable: true,
            });
        } finally {
            setIsCreatingWatchlistPlaylists(false);
        }
    };

    const handleInitializeWatchlistPlaylists = async () => {
        setIsInitializingWatchlistPlaylists(true);
        try {
            const result = await initializeWatchlistPlaylists();
            toast({
                title: 'Success',
                description: `Initialized Watchlist playlists for ${result.updatedUsers} users`,
                status: 'success',
                duration: 5000,
                isClosable: true,
            });
        } catch (error) {
            toast({
                title: 'Error',
                description: 'Failed to initialize Watchlist playlists',
                status: 'error',
                duration: 5000,
                isClosable: true,
            });
        } finally {
            setIsInitializingWatchlistPlaylists(false);
        }
    };

    const handleUpdateMissingImagePaths = async () => {
        setIsUpdatingImagePaths(true);
        try {
            const result = await updateMissingImagePaths();
            toast({
                title: 'Success',
                description: 'Image path update process started',
                status: 'success',
                duration: 5000,
                isClosable: true,
            });
        } catch (error) {
            toast({
                title: 'Error',
                description: 'Failed to update image paths',
                status: 'error',
                duration: 5000,
                isClosable: true,
            });
        } finally {
            setIsUpdatingImagePaths(false);
        }
    };

    const handleUpdateAllFilmOverviews = async () => {
        setIsUpdatingFilmOverviews(true);
        try {
            await updateAllFilmOverviews();
            toast({
                title: "Success",
                description: "All film overviews have been updated.",
                status: "success",
                duration: 5000,
                isClosable: true,
            });
        } catch (error) {
            toast({
                title: "Error",
                description: "Failed to update film overviews.",
                status: "error",
                duration: 5000,
                isClosable: true,
            });
        } finally {
            setIsUpdatingFilmOverviews(false);
        }
    };

    const handleUpdateAllFilmImdbIds = async () => {
        setIsUpdatingFilmImdbIds(true);
        try {
            await updateAllFilmImdbIds();
            toast({
                title: "Success",
                description: "Film imdbId update process started.",
                status: "success",
                duration: 5000,
                isClosable: true,
            });
        } catch (error) {
            toast({
                title: "Error",
                description: "Failed to update film imdbIds.",
                status: "error",
                duration: 5000,
                isClosable: true,
            });
        } finally {
            setIsUpdatingFilmImdbIds(false);
        }
    };

    const handleUpdateFollowRecords = async () => {
        setIsUpdatingFollowRecords(true);
        try {
            const result = await updateAllFollowRecords();
            toast({
                title: "Success",
                description: `Updated ${result.updatedRecords} follow records across ${result.updatedUsers} users`,
                status: "success",
                duration: 5000,
                isClosable: true,
            });
        } catch (error) {
            toast({
                title: "Error",
                description: "Failed to update follow records",
                status: "error",
                duration: 5000,
                isClosable: true,
            });
        } finally {
            setIsUpdatingFollowRecords(false);
        }
    };

    //Reset These to Different Feature film ID's for an update to Front Page
    // in order to find the Id go to API and use the Search-movie route with the name of the movie then grab the id to be manually update
    // in our own code below

    // Bullet Train
    const featuredFilmId0 = '718930';
    // Captain Fantastic
    const featuredFilmId1 = '334533';
    // Tenet
    const featuredFilmId2 = '577922';
    // Love Actually
    const featuredFilmId3 = '508';
   
    const isMobile = useMedia('(max-width: 767px)');

    const getFilm = async (featuredFilmId) => {
        try{
            const filmIdResponse = await searchTmdbFilmById(featuredFilmId);
            return filmIdResponse;
        }
        catch (error) {
            console.log(error + "error in feature film call")
        };
    };

    useEffect(() => {
        let isMounted = true;
      
        const loadFeaturedFilms = async () => {
          try {
            const tempFilmsArray = [];
            const filmIds = [featuredFilmId0, featuredFilmId1, featuredFilmId2, featuredFilmId3];
      
            for (let id of filmIds) {
              const film = await getFilm(id);
              if (isMounted) {
                tempFilmsArray.push(film);
              }
            }
      
            if (isMounted) {
              setFeaturedFilmsArray(tempFilmsArray);
              setFeatureIsLoading(false);
            }
          } catch (error) {
            console.error("Error loading featured films:", error);
            if (isMounted) {
              setFeatureIsLoading(false);
            }
          }
        };
      
        loadFeaturedFilms();
      
        return () => {
          isMounted = false;
        };
      }, []);

    // useEffect(async () => {
    //     const tempFilmsArray = [];

    //     const film0 = await getFilm(featuredFilmId0);
    //     tempFilmsArray.push(film0);

    //     const film1 = await getFilm(featuredFilmId1);
    //     tempFilmsArray.push(film1);

    //     const film2 = await getFilm(featuredFilmId2);
    //     tempFilmsArray.push(film2);

    //     const film3 = await getFilm(featuredFilmId3);
    //     tempFilmsArray.push(film3);

    //     setFeaturedFilmsArray(tempFilmsArray);

    //     setFeatureIsLoading(false);

    // }, [])

    return (
        <>
            {!isMobile ? (
                <> 
                <Box pt="246px"></Box>
                <Heading className='shimmer' colorScheme='qTurquoise' variant='custom-tq' fontFamily="qFont" fontSize='8xl'>
                QUARTILE
                </Heading>
                <Link 
                    href='/about'
                    _hover={{ 
                        textDecoration: 'underline', 
                        textDecorationColor: '#ED64A6'
                    }}
                >
                    <HStack spacing={2} justifyContent="center">
                        <Heading fontFamily="categoryFont" fontSize={'md'} letterSpacing="widest" color='pink.400'>
                            Film Rating System
                        </Heading>
                        <Box mt='-1px'>
                            <FaInfoCircle size={12} color="#ED64A6" />
                        </Box>
                    </HStack>
                </Link>    
            </>
            ) : null} 

            <Spacer></Spacer>

            {!isMobile ? (
                <> 
                <Container  paddingTop='20'  maxW= 'container.xl'>
                    <Heading align='left' fontFamily="categoryFont" paddingTop='6' fontSize='sm' fontWeight='lighest' letterSpacing="0.4em" marginBottom= '-8'>
                            Featured Films
                    </Heading>
                </Container>
            </>
            ) : (
                <Container paddingLeft='12' paddingTop='3' maxW= 'container.xl'>
                    {searchType === 'films' && (
                        <Heading align='left' fontFamily="categoryFont" fontSize='xs' fontWeight='lighest' letterSpacing="0.4em" marginBottom='-4'>
                            Featured
                        </Heading>
                    )}
                </Container>
            )} 

            {/* delete this button after the db ratingcount is working */}
            {/* <Box mt={4} mb={4}>
                <Button
                    onClick={handleUpdateFilmRatingCounts}
                    isLoading={isUpdatingFilmRatingCounts}
                    loadingText="Updating"
                    colorScheme="qTurquoise"
                    variant="outline"
                >
                    Update All Film Rating Counts
                </Button>
                {isUpdatingFilmRatingCounts && (
                    <Box mt={2}>
                        <Spinner color="qTurquoise.500" size="sm" mr={2} />
                        <Text display="inline">Updating rating counts...</Text>
                    </Box>
                )}
            </Box> */}

            {/* delete this button after the db user ratingcount is working */}
            {/* <Box mt={4} mb={4}>
                <Button
                    onClick={handleUpdateUserRatingCounts}
                    isLoading={isUpdatingUserRatingCounts}
                    loadingText="Updating"
                    colorScheme="qTurquoise"
                    variant="outline"
                    ml={2}
                >
                    Update All User Rating Counts
                </Button>
                {isUpdatingUserRatingCounts && (
                    <Box mt={2}>
                        <Spinner color="qTurquoise.500" size="sm" mr={2} />
                        <Text display="inline">Updating user rating counts...</Text>
                    </Box>
                )}
            </Box> */}

            {/* delete this button after the db playlistcount is working */}
            {/* <Box mt={4} mb={4}>
                <Button
                    onClick={handleUpdateUserPlaylistCounts}
                    isLoading={isUpdatingUserPlaylistCounts}
                    loadingText="Updating"
                    colorScheme="qTurquoise"
                    variant="outline"
                    ml={2}
                >
                    Update All User Playlist Counts
                </Button>
                {isUpdatingUserPlaylistCounts && (
                    <Box mt={2}>
                        <Spinner color="qTurquoise.500" size="sm" mr={2} />
                        <Text display="inline">Updating user playlist counts...</Text>
                    </Box>
                )}
            </Box>

           

            <Box mt={4} mb={4}>
                <Button
                    onClick={handleUpdateAllFilmGenres}
                    isLoading={isUpdatingFilmGenres}
                    loadingText="Updating"
                    colorScheme="qTurquoise"
                    variant="outline"
                >
                    Update All Film Genres
                </Button>
                {isUpdatingFilmGenres && (
                    <Box mt={2}>
                        <Spinner color="qTurquoise.500" size="sm" mr={2} />
                        <Text display="inline">Updating film genres...</Text>
                    </Box>
                )}           
            </Box>


            


            {/* Add this with the other commented-out buttons */}
            {/* <Box mt={4} mb={4}>
                <Button
                    onClick={handleCreateAllRatingsPlaylists}
                    isLoading={isCreatingAllRatingsPlaylists}
                    loadingText="Creating"
                    colorScheme="qTurquoise"
                    variant="outline"
                    ml={2}
                >
                    Create All Ratings Playlists
                </Button>
                {isCreatingAllRatingsPlaylists && (
                    <Box mt={2}>
                        <Spinner color="qTurquoise.500" size="sm" mr={2} />
                        <Text display="inline">Creating All Ratings playlists...</Text>
                    </Box>
                )}
            </Box>

            <Box mt={4} mb={4}>
                <Button
                    onClick={handleUpdateMissingImagePaths}
                    isLoading={isUpdatingImagePaths}
                    loadingText="Updating"
                    colorScheme="qTurquoise"
                    variant="outline"
                    ml={2}
                >
                    Update Missing Image Paths
                </Button>
                {isUpdatingImagePaths && (
                    <Box mt={2}>
                        <Spinner color="qTurquoise.500" size="sm" mr={2} />
                        <Text display="inline">Updating image paths...</Text>
                    </Box>
                )}
            </Box> */}

            {/* <Box mt={4} mb={4}>
                <Button
                    onClick={handleUpdateAllFilmGenres}
                    isLoading={isUpdatingFilmGenres}
                    loadingText="Updating"
                    colorScheme="qTurquoise"
                    variant="outline"
                >
                    Update All Film Genres
                </Button>
                {isUpdatingFilmGenres && (
                    <Box mt={2}>
                        <Spinner color="qTurquoise.500" size="sm" mr={2} />
                        <Text display="inline">Updating film genres...</Text>
                    </Box>
                )}           
            </Box> */}

            {/* <Box mt={4} mb={4}>
                <Button
                    onClick={handleUpdateAllFilmOverviews}
                    isLoading={isUpdatingFilmOverviews}
                    loadingText="Updating"
                    colorScheme="qTurquoise"
                    variant="outline"
                >
                    Update All Film Overviews
                </Button>
                {isUpdatingFilmOverviews && (
                    <Box mt={2}>
                        <Spinner color="qTurquoise.500" size="sm" mr={2} />
                        <Text display="inline">Updating film overviews...</Text>
                    </Box>
                )}           
            </Box> */}

            {/* <Box mt={4} mb={4}>
                <Button
                    onClick={handleUpdateAllFilmImdbIds}
                    isLoading={isUpdatingFilmImdbIds}
                    loadingText="Updating"
                    colorScheme="qTurquoise"
                    variant="outline"
                >
                    Update All Film ImdbIds
                </Button>
                {isUpdatingFilmImdbIds && (
                    <Box mt={2}>
                        <Spinner color="qTurquoise.500" size="sm" mr={2} />
                        <Text display="inline">Updating film imdbIds...</Text>
                    </Box>
                )}           
            </Box> */}

            {/* <Box mt={4} mb={4}>
                <Button
                    onClick={handleUpdateFollowRecords}
                    isLoading={isUpdatingFollowRecords}
                    loadingText="Updating"
                    colorScheme="qTurquoise"
                    variant="outline"
                >
                    Update All Follow Records
                </Button>
                {isUpdatingFollowRecords && (
                    <Box mt={2}>
                        <Spinner color="qTurquoise.500" size="sm" mr={2} />
                        <Text display="inline">Updating follow records...</Text>
                    </Box>
                )}           
            </Box> */}

            {/* <Box mt={4} mb={4}>
                <Button
                    onClick={handleCreateWatchlistPlaylists}
                    isLoading={isCreatingWatchlistPlaylists}
                    loadingText="Creating"
                    colorScheme="qTurquoise"
                    variant="outline"
                    ml={2}
                >
                    Create Watchlist Playlists
                </Button>
                {isCreatingWatchlistPlaylists && (
                    <Box mt={2}>
                        <Spinner color="qTurquoise.500" size="sm" mr={2} />
                        <Text display="inline">Creating Watchlist playlists...</Text>
                    </Box>
                )}
                </Box>

            <Box mt={4} mb={4}>
                <Button
                    onClick={handleInitializeWatchlistPlaylists}
                    isLoading={isInitializingWatchlistPlaylists}
                    loadingText="Initializing"
                    colorScheme="qTurquoise"
                    variant="outline"
                    ml={2}
                >
                    Initialize Watchlist Playlists
                </Button>
                {isInitializingWatchlistPlaylists && (
                    <Box mt={2}>
                        <Spinner color="qTurquoise.500" size="sm" mr={2} />
                        <Text display="inline">Initializing Watchlist playlists...</Text>
                    </Box>
                )}
            </Box> */}
                
            { featureIsLoading ? < Spinner p = '20' color= 'qTurquoise.500' size={'xl'}/> : (
                <>
                    {(!isMobile || (isMobile && searchType === 'films')) && (
                        <SimpleGrid className='fade-in' columns={[2, 4]} spacing={[4,10]} >
                            <Box padding={['10px','30px']}>
                                <div >
                                    <FilmCard
                                        title={featuredFilmsArray[0]?.title}
                                        isFeaturedCard={true}
                                        year={featuredFilmsArray[0]?.release_date}
                                        imagePath={featuredFilmsArray[0]?.backdrop_path ? featuredFilmsArray[0]?.backdrop_path : featuredFilmsArray[0]?.poster_path}
                                        filmId={featuredFilmsArray[0]?.id}
                                        setScene={setScene}
                                        setSelectedFilm={setSelectedFilm}
                                    />
                                </div>
                            </Box>

                            <Box padding={['10px','30px']}>
                                <div >
                                    <FilmCard
                                        title={featuredFilmsArray[1]?.title}
                                        isFeaturedCard={true}
                                        year={featuredFilmsArray[1]?.release_date}
                                        imagePath={featuredFilmsArray[1]?.backdrop_path ? featuredFilmsArray[1]?.backdrop_path : featuredFilmsArray[1]?.poster_path}
                                        filmId={featuredFilmsArray[1]?.id}
                                        setScene={setScene}
                                        setSelectedFilm={setSelectedFilm}
                                    />
                                </div>
                            </Box>

                            <Box padding={['10px','30px']}>
                                <div >
                                    <FilmCard
                                        title={featuredFilmsArray[2]?.title}
                                        isFeaturedCard={true}
                                        year={featuredFilmsArray[2]?.release_date}
                                        imagePath={featuredFilmsArray[2]?.backdrop_path ? featuredFilmsArray[2]?.backdrop_path : featuredFilmsArray[2]?.poster_path}
                                        filmId={featuredFilmsArray[2]?.id}
                                        setScene={setScene}
                                        setSelectedFilm={setSelectedFilm}
                                    />
                                </div>
                            </Box>

                            <Box padding={['10px','30px']}>
                                <div >
                                    <FilmCard
                                        title={featuredFilmsArray[3]?.title}
                                        isFeaturedCard={true}
                                        year={featuredFilmsArray[3]?.release_date}
                                        imagePath={featuredFilmsArray[3]?.backdrop_path ? featuredFilmsArray[3]?.backdrop_path : featuredFilmsArray[3]?.poster_path}
                                        filmId={featuredFilmsArray[3]?.id}
                                        setScene={setScene}
                                        setSelectedFilm={setSelectedFilm}
                                    />
                                </div>
                            </Box>

                        </SimpleGrid>
                    )}
                </>
            )}

           
        </>
    );
};

export default HomeScene;