import React from 'react';
import {Box, Image, Badge, VStack, Heading, IconButton, StackDivider, LinkBox, LinkOverlay, useColorModeValue, Button, Spinner} from '@chakra-ui/react';
// import {getQuartileFilm, getUserRating, searchTmdbFilmById, getQuartileFilmByQId} from '../api';
import { useNavigate, Link } from 'react-router-dom';
import { IoCloseCircle } from 'react-icons/io5'; 

// import { CloseIcon } from '@chakra-ui/icons';

const PlaylistFilmCard = (props) => {
    const [isLoading, setIsLoading] = React.useState(true);
    const [tmdbFilmData, setTmdbFilmData] = React.useState({});
    const navigate = useNavigate();

    const {
        filmId,
        tmdbFilmId,
        title,
        year,
        fullImgPath,
        avgQRating,
        isFeaturedCard= false,
        setSelectedFilm,
        isEditMode,
        onRemove
    } = props;
    
    const borderColor = useColorModeValue("black", "white");
    // const textColor = useColorModeValue("black", "white");
    const bgColor = useColorModeValue("white", "black");


    // Image Logic - HomePage.jsx is passing backdrop img, if not there, pass poster img, if not there use default
    // const imageBaseUrl = 'https://image.tmdb.org/t/p/original';
    // const defaultImage = 'https://bit.ly/2Z4KKcF';
    // // const fullImgPath = imagePath ? `${imageBaseUrl}${imagePath}` : defaultImage;

   
    //extract qRatingAverage
    // React.useEffect(() => {
    //     const loadFilmRatings = async () => {
    //         const film = await getQuartileFilm(filmId);
    //         setQuartileFilmData(film);
    //         setIsLoading(false);
    //     }
    //     loadFilmRatings();
    // }, [filmId]);

    // need to refactor ths below statement to retrive the qAvg Rating to use in the useEffect,
    // not the individual users ratings on the playlist items



    // ****need to save/export the imageBase url image path etc for export here? 
    // New UseEffect to be modified****
    // React.useEffect(() => {
    //     const loadFilmDetailsAndQRatings = async () => {
    
    //         // need to update this search for the tmdbID not using our id here, 
    //         // need to search for the tmdbFilmId based on a qfilm search by filmId input
    //         // film.filmTmdbId
    //         const filmDetails = await getQuartileFilmByQId(filmId);
    //         setQuartileFilmData(filmDetails);
    //         setTmdbFilmId(filmDetails.tmdbFilmId);
    //         console.log(filmId);

    //         // probably need to set avgQrating somewhere 

    //         const tmdbFilm = await searchTmdbFilmById(filmDetails.filmTmdbId);
    //         const imageBaseUrl = 'https://image.tmdb.org/t/p/original';
    //         const defaultImage = 'https://bit.ly/2Z4KKcF';
    //         const imagePath= tmdbFilm?.backdrop_path ? tmdbFilm?.backdrop_path : tmdbFilm?.poster_path;
    //         const fullImgPath = imagePath ? `${imageBaseUrl}${imagePath}` : defaultImage;
    //         setTmdbFilmData({...tmdbFilm, fullImgPath, imagePath});
    //         // getQuartile Film Data
    //         // const film = await getQuartileFilm(filmId);
    //         // setQuartileFilmData(film);

    //         // code to get user rating data in the future
    //         // if (loggedIn) {
    //         //     const rating = await getUserRating({
    //         //         filmTmdbId: filmId, user_id: userId
    //         //     });
    //         //     if (rating) {
    //         //         setHasUserRated(true);
    //         //         setQRating(rating.filmQRating);
    //         //         setPlotRating(rating.filmPlotRating);
    //         //         setActingRating(rating.filmActingRating);
    //         //         setCinematographyRating(rating.filmCinematographyRating);
    //         //         setNoveltyRating(rating.filmNoveltyRating);
    //         //         setEndingRating(rating.filmEndingRating);
    //         //     }
    //         // }

    //         setIsLoading(false);
    //     }
    //     loadFilmDetailsAndQRatings();
    // }, [filmId]);


    
     // TODO: Pass in full film object to the Film Card, needs to be set below
    // ***** this data is not being passed through correctly because it's not leading to the correct page****
     const handleClick = () => {
        if (isEditMode) return; // Don't navigate if in edit mode
        
        const filmDetails = {
            filmId,
            tmdbFilmId,
            avgQRating,
            fullImgPath,
            title,
            year,
            isFeaturedFilm: isFeaturedCard,
        };
        setSelectedFilm(filmDetails);
        navigate(`/films/${tmdbFilmId}`);
    };

     // Year Logic - If no trimmedYear exists display nothing for year
    const trimmedYear = parseInt(year);

    const formatRating = (rating) => {
        if (rating === 10) {
            return '10';
        } else {
            return rating?.toFixed(1);
        }
    };
    
    const renderQRating = () => {
        if (avgQRating) {
            return formatRating(avgQRating);
        }
        return '?';
    }

    // need to update to remove the is feature piece since there isn't a feature on the playlist?
    // or maybe leave so we can add some other type of variation here on the page in the future
    const renderFilmCard = () => {
        const cardContent = (
            <VStack class={isFeaturedCard ? "featureFilmCardVstack" : "containerdiv"} position="relative">
                <Image
                    src={fullImgPath}
                    alt="Movie Pic Placeholder"
                    class={isFeaturedCard ? "featureFilmCardFormat" : "filmCardFormat"}
                    style={{ border: `3px solid ${borderColor}` }}
                />

                <VStack 
                    class={isFeaturedCard ? "featureQCardFormat" : "QCardFormat"} 
                    style={{ border: `3px solid ${borderColor}`, backgroundColor: bgColor }}
                >
                    <Heading 
                        class={isFeaturedCard ? "featureQCardNumberFormat" : "QCardNumberFormat"}
                        justify-content={isFeaturedCard ? "flex-end" : "flex-end"}
                    >
                        {renderQRating()}
                    </Heading>
                    <Image
                        src="/QLogoTest1.png"
                        alt="q Logo "
                        class={isFeaturedCard ? "featureQCardIconFormat" : "QCardIconFormat"}
                    />
                </VStack>

                <Heading class={isFeaturedCard ? "featureQCardTitleFormat" : "QCardTitleFormat"}>
                    {title}
                </Heading>
                {trimmedYear && (
                    <Heading class={isFeaturedCard ? 'featureQCardYearFormat' : 'QCardYearFormat'}>
                        {trimmedYear}
                    </Heading>
                )}
                {isEditMode && (
                    <IconButton
                        aria-label="Remove film"
                        icon={<IoCloseCircle size="100%" />}
                        position="absolute"
                        top={-5}
                        right={-5}
                        width="60px"
                        height="60px"
                        borderRadius="15%"
                        border={`3px solid ${borderColor}`}
                        colorScheme="red"
                        onClick={(e) => {
                            e.stopPropagation();
                            onRemove();
                        }}
                    />
                )}
            </VStack>
        );

        return (
            <Box 
                as="div" 
                onClick={handleClick}
                style={{ cursor: isEditMode ? 'default' : 'pointer' }}
            >
                {!isEditMode && (
                    <Link 
                        to={`/films/${tmdbFilmId}`}
                        onClick={(e) => e.stopPropagation()}
                        style={{ textDecoration: 'none', color: 'inherit' }}
                    >
                        {cardContent}
                    </Link>
                )}
                {isEditMode && cardContent}
            </Box>
        );
    }

    return (
        <>
            {renderFilmCard()}
        </>
    );


}

export default PlaylistFilmCard;