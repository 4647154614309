import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { useMedia } from 'react-use';
import { Helmet } from 'react-helmet-async';
import {
    VStack,
    useToast,
} from '@chakra-ui/react';
import TopNav from './TopNav';
import MobileTopNav from './MobileTopNav';
import HomeScene from './Scenes/HomeScene';
import SearchResultsScene from "./Scenes/SearchResultsScene";
import AltNav from "./AltNav";
import MobileAltNav from './MobileAltNav';

const HomePage = (props) => {

    const {
        setLoggedIn,
        loggedIn,
        handleLogout,
        userId,
        customSwitchChecked,
        setCustomSwitchChecked,
        colorMode,
        toggleColorMode
    } = props;

    const { topcategory, genre, searchresults } = useParams();
    const [scene, setScene] = useState('HOME');
    const [prevScene, setPrevScene] = useState('');
    const [searchFilmsResponse, setSearchFilmsResponse] = useState([]);
    const [allResults, setAllResults] = useState([]);
    const [searchType, setSearchType] = useState('films');
    const [selectedFilm, setSelectedFilm] = useState([]);
    const [filterIsLoading, setFilterIsLoading] = useState(false);
    const [selectedGenres, setSelectedGenres] = useState([]);
    const [toastShown, setToastShown] = useState(false);
    const toast = useToast();

    const isMobile = useMedia('(max-width: 767px)');

    useEffect(() => {
      const hasShownBetaToast = localStorage.getItem('betaToastShown');
      if (!hasShownBetaToast && !isMobile) {
          toast({
              title: 'Think Spotify, But For Movies',
              description:
                  "Find and rate films, make your own playlists, and follow friends! Learn more about our rating system on the about page.",
              status: 'warning',
              duration: 55000,
              position: 'bottom-left',
              isClosable: true,
              onCloseComplete: () => {
                  localStorage.setItem('betaToastShown', 'true');
              },
          });
      }
  }, [toast, isMobile]);
  
  //   useEffect(() => {
  //     toast({
  //         title: 'Thanks For Being a Beta User',
  //         description:
  //             " Create a Quartile account to rate films, make your own playlists, and follow friends! Think spotify but for movies. To learn more about how our creative rating system works, visit the about page. We appreciate any feedback you have about the site or ideas for new features you would like to see. Contact us at info@quartilefilm.io.",
  //         status: 'warning',
  //         duration: 55000,
  //         position: isMobile ? 'top' : 'bottom-left',
  //         isClosable: true,
  //     });
  // }, [])

    // Mobile vs Web Nav Bar Rendering

    const renderTopNav = (scene) => {
      if (isMobile) {
        if (scene === 'HOME' || scene === 'SEARCH_RESULTS') {
          return (
            <MobileTopNav
              setScene={setScene}
              setSearchFilmsResponse={setSearchFilmsResponse}
              setFilterIsLoading={setFilterIsLoading}
              setLoggedIn={setLoggedIn}
              loggedIn={loggedIn}
              handleLogout={handleLogout}
              customSwitchChecked={customSwitchChecked}
              setCustomSwitchChecked={setCustomSwitchChecked}
              colorMode={colorMode}
              toggleColorMode={toggleColorMode}
              userId={userId}
              setSearchType={setSearchType}
              searchType={searchType}
              selectedGenres={selectedGenres}
              setSelectedGenres={setSelectedGenres}
              setAllResults={setAllResults}
              allResults={allResults}
            />
          );
        } else {
          return (
            <MobileAltNav
              setScene={setScene}
              setLoggedIn={setLoggedIn}
              loggedIn={loggedIn}
              handleLogout={handleLogout}
              customSwitchChecked={customSwitchChecked}
              setCustomSwitchChecked={setCustomSwitchChecked}
              colorMode={colorMode}
              toggleColorMode={toggleColorMode}
              userId={userId} 
            />
          );
        }
      } else {
        if (scene === 'HOME' || scene === 'SEARCH_RESULTS') {
          return (
            <TopNav
              setScene={setScene}
              searchFilmsResponse={searchFilmsResponse}
              setSearchFilmsResponse={setSearchFilmsResponse}
              setFilterIsLoading={setFilterIsLoading}
              setLoggedIn={setLoggedIn}
              loggedIn={loggedIn}
              handleLogout={handleLogout}
              customSwitchChecked={customSwitchChecked}
              setCustomSwitchChecked={setCustomSwitchChecked}
              colorMode={colorMode}
              toggleColorMode={toggleColorMode}
              searchType={searchType}
              setSearchType={setSearchType}
              userId={userId} 
              selectedGenres={selectedGenres}
              setSelectedGenres={setSelectedGenres}
              allResults={allResults}
              setAllResults={setAllResults}
            />
          );
        } else {
          return (
            <AltNav
              setLoggedIn={setLoggedIn}
              loggedIn={loggedIn}
              handleLogout={handleLogout}
              customSwitchChecked={customSwitchChecked}
              setCustomSwitchChecked={setCustomSwitchChecked}
              colorMode={colorMode}
              toggleColorMode={toggleColorMode}
              userId={userId} 
            />
          );
        } 
    }
}
              
    return (
        <>
            <Helmet>
                <title>Quartile - Think Spotify, But For Movies</title>
                <meta name="description" content="Discover and rate movies with Quartile. Create playlists, follow other users, and find your next favorite film." />
                
                {/* OpenGraph Meta Tags */}
                <meta property="og:title" content="Quartile - Think Spotify, But For Movies" />
                <meta property="og:description" content="Discover and rate movies with Quartile. Create playlists, follow other users, and find your next favorite film." />
                <meta property="og:type" content="website" />
                <meta property="og:url" content="https://quartilefilm.io" />
                <meta property="og:image" content="https://quartilefilm.io/og-image.png" />
                <meta property="og:site_name" content="Quartile" />
                
                {/* Twitter Card Meta Tags */}
                <meta name="twitter:card" content="summary_large_image" />
                <meta name="twitter:site" content="@QuartileFilm" />
                <meta name="twitter:title" content="Quartile - Think Spotify, But For Movies" />
                <meta name="twitter:description" content="Discover and rate movies with Quartile. Create playlists, follow other users, and find your next favorite film." />
                <meta name="twitter:image" content="https://quartilefilm.io/og-image.png" />
            </Helmet>
            <VStack marginTop={'-8px'}>
                {renderTopNav(scene)}
                {scene === 'HOME' && (
                    <HomeScene
                        setScene={setScene}
                        setSelectedFilm={setSelectedFilm}
                        searchType={searchType}
                    />
                )}
                {scene === 'SEARCH_RESULTS' && (
                    <SearchResultsScene
                        results={searchFilmsResponse}
                        setScene={setScene}
                        setSelectedFilm={setSelectedFilm}
                        filterIsLoading={filterIsLoading}
                        setSearchType={setSearchType}
                        searchType={searchType}
                        selectedGenres={selectedGenres}
                        setSelectedGenres={setSelectedGenres}
                    />
                )}
            </VStack>
        </>
    );
}

export default HomePage;